import { useQueryClient } from "@tanstack/vue-query";
import { unref, type MaybeRef } from "vue";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { createQuery, type MyQueryOptions } from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";
import { createBookingViewModel } from "~/src/models/Case/Booking.viewmodel";

const route = parseUriTemplate("/api/hcd/booking/{bookingId}");
export const useBookingService = () => {
  const { $api } = useOurNuxtApp();
  const client = useQueryClient();

  const getBookingByScreeningId = async (id: string, signal?: AbortSignal) => {
    const result = await $api(route.expand({ bookingId: id }), {
      method: "GET",
      signal,
    });
    return createBookingViewModel(result);
  };
  const useGetBookingQuery = (
    screeningId: MaybeRef<string | null>,
    options?: MyQueryOptions
  ) => {
    return createQuery(
      [QUERY_KEYS.Screening.get, screeningId],
      ({ signal }) => {
        const id = unref(screeningId);
        if (!id) return null;
        return getBookingByScreeningId(id, signal);
      },
      { ...options, suspense: false }
    );
  };

  const getBookingByScreeningIdCached = (id: string) =>
    client.fetchQuery({
      queryKey: [QUERY_KEYS.Screening.get, id],
      queryFn: ({ signal }) => getBookingByScreeningId(id, signal),
      retry: false,
    });

  return {
    useGetBookingQuery,

    getBookingByScreeningId,
    getBookingByScreeningIdCached,
  };
};
